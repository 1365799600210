$(document).ready(function(){

  $('.m-menu').click(function(){
    if ($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
    }else{
      $('body').addClass('m-menu-open');
    }
  });

  if($('body').hasClass('home_tmp')){
    (function shownext(jq){
      jq.eq(0).fadeIn(1800, function(){
          jq.eq(0).delay(5000).fadeOut(1800,function(){
            jq = jq.slice(1)
            if(!jq.length){
              jq = $('.ticker').find('.tick')
            }
            shownext(jq);
          })
      });
    })($('.tick'))
  }

  if($('body').hasClass('home')){
    $('.exhibitions').slick({
      autoplay: true,
      arrows: false,
      autoplaySpeed: 5000,
      // cssEase: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)'
      cssEase: 'cubic-bezier(1,.33,.21,.96)',
      speed:750
    });
  }

  if($('body').hasClass('page-exhibition') || $('body').hasClass('page-general') || $('body').hasClass('page-news-detail')){
    $('.imgs').slick({
      variableWidth: true,
      cssEase: 'cubic-bezier(1,.33,.21,.96)',
      speed:750,
      appendArrows:$('.slick-pager'),
      responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        }
      }]
    });
  }

  // Render a dummy subnav to make space for the real subnav
  function renderDummyNav(){
    $('.main-nav ul li ul').each(function(){
      var clone = $(this).clone();
      clone.find('li').html('&nbsp;');
      clone.addClass('dummy');
      clone.appendTo($(this).parent());
    });
  }
  renderDummyNav();


  // Infinite scroll for collection pages
  if($('body').hasClass('page-collection')){

    // sometimes we have double years when appending new works, hide them in necessary
    var infScroll = $('.works').data('infiniteScroll');
    if(infScroll != undefined){
      infScroll.on( 'append', function( response, path, items ) {
        $('.year').each(function(){
          var nextYear = $(this).parent().next().find('.year');
          if(nextYear.html() == $(this).html()){
            nextYear.hide();
          }
        })
      });
    }

    // add collection scroll buttons if necessary
    if($('.filters')[0].scrollWidth > $('.filters').width()){
      $('.main').prepend('<div class="scrollers"><div class="prev"></div><div class="next"></div></div>')
      $(document).on('click','.prev',function(){
        // $('.filters')[0].scrollLeft -= 40;
        var newpos = $('.filters')[0].scrollLeft - 100;
        $('.filters').animate({scrollLeft: newpos}, 200);
      })
      $(document).on('click','.next',function(){
        // $('.filters')[0].scrollLeft += 40;
        var newpos = $('.filters')[0].scrollLeft + 100;
        $('.filters').animate({scrollLeft: newpos}, 200);
      })
    }

  }


  // search button
  $('.search-btn').click(function(e){
    if(!$('body').hasClass('search-open')){
      e.preventDefault();
      $('body').addClass('search-open');
      $('.search-field').focus();
    }else{
      if($('.search-field').val() == ''){
        e.preventDefault();
        $('body').removeClass('search-open');
      }
    }
  })


  // Ajax paging on work detail pages
  function loadWork(URL){
    $(".main").load(URL);
    // update the subnavigation highlight
    // console.log(URL);
    $('.collection-subnav li').removeClass('active');
    // look for the new subcategory
    var arr = URL.split('/');
    var newCat = arr[arr.length - 2];
    $( ".collection-subnav li a" ).each(function( index ) {
      var a = $(this).attr('href');
      var hascat = a.search(newCat);
      if(hascat != -1){
        $(this).parent().addClass('active');
      }
    });

    // rebuil the dummy navigation
    $('.dummy').remove();
    renderDummyNav();


    // and set it
  }

  $('.page-work').on('click', 'a.pn', function(e) {
    e.preventDefault();
    var URL = $(this).attr('href');
    var state = URL
    var title = ''
    history.pushState(state, title, URL)
    loadWork(URL);
  });

  window.addEventListener('popstate', function (event) {
    if(event.state != '' && $('body').hasClass('page-work')){
      // console.log('load pop state URL')
      loadWork(event.state);
    }
  });


});